<template>
	<div class="delivery-record-details position-relative w-100 h-100 px-4 py-3 d-flex justify-content-center overflow-hidden">
		<b-modal v-model="modal" centered hide-header-close
		         ok-variant="danger"
		         cancel-variant="custom-light"
		         v-bind:ok-disabled="!password || !recordNo"
		         v-on:ok="deleteDeliveryRecord"
		         v-on:hide="resetModal">
			<h3 slot="modal-title">Delete Delivery Record</h3>
			<h5 slot="modal-ok" class="m-0">Confirm</h5>
			<h5 slot="modal-cancel" class="m-0">Cancel</h5>
			<form action="" class="container-modal-form position-relative w-100 h-100 p-3">
				<div class="form-group position-relative">
					<label for="password" class="font-weight-bold">Password</label>
					<input type="password" id="password" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': pwInvalid}"
					       v-model="password"
					       v-on:input="pwInvalid = false">
				</div>
				<div class="form-group position-relative">
					<label for="modalRecordNo" class="font-weight-bold">Record No.</label>
					<input type="text" id="modalRecordNo" class="form-control position-relative w-100"
					       v-bind:class="{'invalid': recordNoInvalid}"
					       v-model="recordNo"
					       v-on:input="recordNoInvalid = false">
				</div>
			</form>
		</b-modal>
		<div class="details position-relative w-50 h-100 rounded bg-white d-flex flex-column align-items-center flex-grow-0 overflow-hidden">
			<form-controls v-bind:msg="msg"
			               v-bind:msg-invalid="msgInvalid">
				<control-button slot="left"
				                v-bind:disabled="false"
				                v-bind:btn-title="backBtnTitle"
				                v-bind:btn-icon="['fas', 'arrow-left']"
				                v-bind:btn-class="backBtnClass"
				                v-on:btn-pressed="goBack"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!savable"
				                btn-title="SAVE"
				                v-bind:btn-icon="['fas', 'hdd']"
				                btn-class="btn-custom-light ml-2"
				                v-on:btn-pressed="saveDeliveryRecord"></control-button>
				<control-button slot="right"
				                v-bind:disabled="!deliveryRecord"
				                btn-title="DELETE"
				                v-bind:btn-icon="['fas', 'trash-alt']"
				                btn-class="btn-danger ml-2"
				                v-on:btn-pressed="modal = true"></control-button>
			</form-controls>
			<form action="" class="container-form position-relative scroll-bar w-100 h-100 p-3">
				<div class="form-group position-relative w-100">
					<label for="recordNo" class="font-weight-bold">Record No.</label>
					<input type="text" id="recordNo" placeholder=""
					       class="form-control position-relative"
					       v-bind:value="localDeliveryRecord.recordNo" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="supplier" class="font-weight-bold">Supplier</label>
					<input type="text" id="supplier" class="form-control position-relative"
					       placeholder="Choose Supplier From The Table"
					       v-bind:class="invalidInputs.supplier"
					       v-bind:value="localDeliveryRecord.supplierName" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="po" class="font-weight-bold">Purchase Order</label>
					<input type="text" id="po" class="form-control position-relative"
					       placeholder="Choose Purchase Order From The Table"
					       v-bind:class="invalidInputs.purchaseOrder"
					       v-bind:value="localDeliveryRecord.purchaseOrder" readonly>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localDeliveryRecord.itemsReceived" v-bind:key="`itemReceived${index}`">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localDeliveryRecord.itemsReceived.length === 1"
						        v-on:click="addDeleteItems('itemsReceived', 'delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item Received {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localDeliveryRecord.itemsReceived.length >= 50"
						        v-on:click="addDeleteItems('itemsReceived','add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemReceived-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`itemReceived-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="itemsReceivedInvalid[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('itemsReceived','name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemReceived-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`itemReceived-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="itemsReceivedInvalid[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('itemsReceived','quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemReceived-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
							<input type="text" v-bind:id="`itemReceived-unitPrice${index}`" placeholder="Unit Price"
							       class="form-control position-relative"
							       v-bind:class="itemsReceivedInvalid[index].unitPrice"
							       v-bind:value="item.unitPrice"
							       v-on:change="updateItems('itemsReceived','unitPrice', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localDeliveryRecord.itemsAccepted" v-bind:key="`itemRAccepted${index}`">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localDeliveryRecord.itemsAccepted.length === 1"
						        v-on:click="addDeleteItems('itemsAccepted', 'delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item Accepted {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localDeliveryRecord.itemsAccepted.length >= 50"
						        v-on:click="addDeleteItems('itemsAccepted','add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemAccepted-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`itemAccepted-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="itemsAcceptedInvalid[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('itemsAccepted','name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemAccepted-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`itemAccepted-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="itemsAcceptedInvalid[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('itemsAccepted','quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemAccepted-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
							<input type="text" v-bind:id="`itemAccepted-unitPrice${index}`" placeholder="Unit Price"
							       class="form-control position-relative"
							       v-bind:class="itemsAcceptedInvalid[index].unitPrice"
							       v-bind:value="item.unitPrice"
							       v-on:change="updateItems('itemsAccepted','unitPrice', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="subform position-relative w-100 h-auto mb-3 border border-custom-light rounded"
				     v-for="(item, index) in localDeliveryRecord.itemsRejected" v-bind:key="`itemRejected${index}`">
					<div class="controls position-relative w-100 p-2 d-flex justify-content-center align-items-center">
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localDeliveryRecord.itemsRejected.length === 1"
						        v-on:click="addDeleteItems('itemsRejected', 'delete', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'minus']">
							</font-awesome-icon>
						</button>
						<h4 class="title position-relative px-4 m-0 text-truncate">Item Rejected {{index + 1}}</h4>
						<button type="button" class="button btn btn-custom-light p-0 flex-shrink-0"
						        v-bind:disabled="localDeliveryRecord.itemsRejected.length >= 50"
						        v-on:click="addDeleteItems('itemsRejected','add', index)">
							<font-awesome-icon class="position-relative w-90 h-100"
							                   v-bind:icon="['fas', 'plus']">
							</font-awesome-icon>
						</button>
					</div>
					<div class="content position-relative w-100 p-3">
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemRejected-name${index}`" class="font-weight-bold">Name</label>
							<input type="text" v-bind:id="`itemRejected-name${index}`" placeholder="Item Name"
							       class="form-control position-relative"
							       v-bind:class="itemsRejectedInvalid[index].name"
							       v-bind:value="item.name"
							       v-on:input="updateItems('itemsRejected','name', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemRejected-quantity${index}`" class="font-weight-bold">Quantity</label>
							<input type="text" v-bind:id="`itemRejected-quantity${index}`" placeholder="Quantity"
							       class="form-control position-relative"
							       v-bind:class="itemsRejectedInvalid[index].quantity"
							       v-bind:value="item.quantity"
							       v-on:input="updateItems('itemsRejected','quantity', $event.target.value, index)">
						</div>
						<div class="form-group position-relative w-100">
							<label v-bind:for="`itemRejected-unitPrice${index}`" class="font-weight-bold">Unit Price</label>
							<input type="text" v-bind:id="`itemRejected-unitPrice${index}`" placeholder="Unit Price"
							       class="form-control position-relative"
							       v-bind:class="itemsRejectedInvalid[index].unitPrice"
							       v-bind:value="item.unitPrice"
							       v-on:change="updateItems('itemsRejected','unitPrice', $event.target.value, index)">
						</div>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="receivedTotal" class="font-weight-bold">Total Amount Received</label>
					<input type="text" id="receivedTotal" placeholder="0.00"
					       class="form-control position-relative"
					       v-bind:value="localDeliveryRecord.receivedTotal" readonly>
				</div>
				<div class="form-group position-relative w-100">
					<label for="receivedBy" class="font-weight-bold">Received By</label>
					<input type="text" id="receivedBy" class="form-control position-relative"
					       placeholder="Choose Among Your Staffs"
					       v-bind:class="invalidInputs.receivedBy"
					       v-bind:value="localDeliveryRecord.receivedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="receivedAtDate" class="font-weight-bold text-truncate">Date Received</label>
						<b-form-datepicker id="receivedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.receivedAtDT.date"
						                   v-bind:value="localDeliveryRecord.receivedAtDT.date"
						                   v-on:input="updateDT('receivedAt', 'receivedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="receivedAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="receivedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.receivedAtDT.time"
						                   v-bind:value="localDeliveryRecord.receivedAtDT.time"
						                   v-on:input="updateDT('receivedAt', 'receivedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="acceptedBy" class="font-weight-bold">Accepted By</label>
					<input type="text" id="acceptedBy" class="form-control position-relative"
					       placeholder="Choose Among Your Staffs"
					       v-bind:class="invalidInputs.acceptedBy"
					       v-bind:value="localDeliveryRecord.acceptedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="acceptedAtDate" class="font-weight-bold text-truncate">Date Accepted</label>
						<b-form-datepicker id="acceptedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.acceptedAtDT.date"
						                   v-bind:value="localDeliveryRecord.acceptedAtDT.date"
						                   v-on:input="updateDT('acceptedAt', 'acceptedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="acceptedAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="acceptedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.acceptedAtDT.time"
						                   v-bind:value="localDeliveryRecord.acceptedAtDT.time"
						                   v-on:input="updateDT('acceptedAt', 'acceptedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="rejectedBy" class="font-weight-bold">Rejected By</label>
					<input type="text" id="rejectedBy" class="form-control position-relative"
					       placeholder="Choose Among Your Staffs"
					       v-bind:class="invalidInputs.rejectedBy"
					       v-bind:value="localDeliveryRecord.rejectedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="rejectedAtDate" class="font-weight-bold text-truncate">Date Rejected</label>
						<b-form-datepicker id="rejectedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:class="invalidInputs.rejectedAtDT.date"
						                   v-bind:value="localDeliveryRecord.rejectedAtDT.date"
						                   v-on:input="updateDT('rejectedAt', 'rejectedAtDT', 'date', $event)">
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="rejectedAtTime" class="font-weight-bold text-truncate">Time</label>
						<b-form-timepicker id="rejectedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:class="invalidInputs.rejectedAtDT.time"
						                   v-bind:value="localDeliveryRecord.rejectedAtDT.time"
						                   v-on:input="updateDT('rejectedAt', 'rejectedAtDT', 'time', $event)">
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="notes" class="font-weight-bold">Notes</label>
					<textarea id="notes" rows="6" class="form-control position-relative"
					          v-model="localDeliveryRecord.notes"></textarea>
				</div>
				<div class="form-group position-relative w-100">
					<label for="updatedBy" class="font-weight-bold">Record Updated By</label>
					<input type="text" id="updatedBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Saved"
					       v-bind:value="localDeliveryRecord.updatedByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="updatedAtDate" class="font-weight-bold text-truncate">Date Updated</label>
						<b-form-datepicker id="updatedAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localDeliveryRecord.updatedAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="updatedAtTime" class="font-weight-bold text-truncate">Time Updated</label>
						<b-form-timepicker id="updatedAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localDeliveryRecord.updatedAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
				<div class="form-group position-relative w-100">
					<label for="createdBy" class="font-weight-bold">Record Created By</label>
					<input type="text" id="createdBy" class="form-control position-relative"
					       placeholder="Quote Not Yet Created"
					       v-bind:value="localDeliveryRecord.createdByName" readonly>
				</div>
				<div class="container-datetime-pickers position-relative d-flex">
					<div class="date-picker-form-group form-group position-relative d-flex flex-column">
						<label for="createdAtDate" class="font-weight-bold text-truncate">Date Created</label>
						<b-form-datepicker id="createdAtDate" placeholder="Date"
						                   class="date-picker"
						                   v-bind:value="localDeliveryRecord.createdAtDT.date" disabled>
						</b-form-datepicker>
					</div>
					<div class="time-picker-form-group form-group position-relative ml-3 d-flex flex-column">
						<label for="createdAtTime" class="font-weight-bold text-truncate">Time Created</label>
						<b-form-timepicker id="createdAtTime" placeholder="Time"
						                   class="time-picker"
						                   v-bind:value="localDeliveryRecord.createdAtDT.time" disabled>
						</b-form-timepicker>
					</div>
				</div>
			</form>
		</div>
		<div class="table-selects position-relative w-50 h-100 ml-3 rounded d-flex flex-column">
			<table-select title="Supplier" filter="name" sort="name"
			              v-bind:order="1"
			              v-bind:fields="suppliersFields"
			              v-bind:data-array="suppliers"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localDeliveryRecord.supplier]"
			              v-on:row-clicked="selectSupplier($event)"
			              v-bind:style="{height: '50% !important'}"></table-select>
			<table-select title="Purchase Order" filter="poNo" sort="issuedAt"
			              v-bind:order="-1"
			              v-bind:fields="purchaseOrderFields"
			              v-bind:data-array="purchaseOrders"
			              v-bind:selectable="true"
			              v-bind:multi-selectable="false"
			              v-bind:selected-ids="[localDeliveryRecord.purchaseOrder]"
			              v-on:row-clicked="selectPurchaseOrder($event)"
			              v-bind:style="{height: '50% !important', marginTop: '16px'}"></table-select>
		</div>
	</div>
</template>

<script>
import FormControls from "@/components/functional/form/FormControls";
import TableSelect from "@/components/functional/table/TableSelect";
import ControlButton from "@/components/functional/form/ControlButton";
import { v4 as uuidv4 } from 'uuid';
import isNumeric from "validator/lib/isNumeric";
import { BModal, BFormDatepicker, BFormTimepicker } from "bootstrap-vue";
import { format } from "date-fns"
import { dateTimeDifferent, itemsDifferent } from "@/utility/helpers";

export default {
	name: "RecordDetails",
	
	components: {
		FormControls,
		TableSelect,
		ControlButton,
		BModal,
		BFormDatepicker,
		BFormTimepicker
	},
	
	props: {
		// props from vue router route params
		recordId: {
			type: String,
		}
	},
	
	beforeRouteLeave(to, from, next) {
		this.modal = false;
		if (this.savable) {
			if (this.msg === "Leave Without Saving?") {
				// user has already been notified
				next();
			} else {
				this.toRoute = to.fullPath;
				this.msg = "Leave Without Saving?";
				this.msgInvalid = true;
				this.backBtnTitle = "YES!";
				this.backBtnClass = "btn-danger mr-2";
			}
		} else {
			next();
		}
	},
	
	created() {
		this.initDeliveryRecord();
		window.addEventListener("beforeunload", this.unloadHandler);
	},
	
	beforeDestroy() {
		window.removeEventListener("beforeunload", this.unloadHandler);
	},
	
	data() {
		return {
			localDeliveryRecord: {
				_id: "",
				recordNo: "",
				supplier: "",
				purchaseOrder: "",
				itemsReceived: [
					{
						name: "",
						quantity: "",
						unitPrice: "",
					},
				],
				itemsAccepted: [
					{
						name: "",
						quantity: "",
						unitPrice: "",
					},
				],
				itemsRejected: [
					{
						name: "",
						quantity: "",
						unitPrice: "",
					},
				],
				receivedTotal: "",
				receivedAt: "",
				acceptedAt: "",
				rejectedAt: "",
				createdAt: "",
				updatedAt: "",
				receivedBy: "",
				acceptedBy: "",
				rejectedBy: "",
				createdBy: "",
				updatedBy: "",
				notes: "",
				// added properties
				poNo: "",
				supplierName: "",
				ReceivedByName: "",
				AcceptedByName: "",
				rejectedByName: "",
				createdByName: "",
				updatedByName: "",
				receivedAtDT: { date: "", time: "" },
				acceptedAtDT: { date: "", time: "" },
				rejectedAtDT: { date: "", time: "" },
				createdAtDT: { date: "", time: "" },
				updatedAtDT: { date: "", time: "" },
			},
			// use v-model on input that does not require validation
			invalidInputs: {
				supplier: "",
				purchaseOrder: "",
				receivedBy: "",
				receivedAtDT: { date: "", time: "" },
				// can be invalid but not required
				acceptedAtDT: { date: "", time: "" },
				rejectedAtDT: { date: "", time: "" },
			},
			itemsReceivedInvalid: [
				{
					name: "",
					quantity: "",
					unitPrice: "",
				},
			],
			itemsAcceptedInvalid: [
				{
					name: "",
					quantity: "",
					unitPrice: "",
				},
			],
			itemsRejectedInvalid: [
				{
					name: "",
					quantity: "",
					unitPrice: "",
				},
			],
			suppliersFields: [
				{ display: "Name", key: "name", classBinding: ["f-5"] },
				{ display: "Supplier No.", key: "supplierNo", classBinding: ["f-2"] },
			],
			purchaseOrderFields: [
				{ display: "PO No.", key: "poNo", classBinding: ["f-3"] },
				{ display: "items", key: "items", classBinding: [] },
				{ display: "Total", key: "totalAmount", classBinding: ["f-2"] },
				{ display: "Issued", key: "issuedAt", classBinding: ["f-3"] },
			],
			msg: "",
			msgInvalid: false,
			modal: false,
			toRoute: "",
			backBtnTitle: "BACK",
			backBtnClass: "btn-custom-light mr-2",
			password: "",
			pwInvalid: false,
			recordNo: "",
			recordNoInvalid: false,
		}
	},
	
	computed: {
		deliveryRecord() {
			return this.$store.state.contents.deliveryRecords.find(({ _id }) => _id === this.recordId);
		},
		
		savable() {
			// fall through pattern
			if (!this.deliveryRecord) return true;
			const itemsKeys = ["itemsReceived", "itemsAccepted", "itemsRejected"];
			const dtKeys = ["receivedAt", "acceptedAt", "rejectedAt"];
			if (itemsKeys.some(key => {
				if (this.deliveryRecord[key].length === 0) {
					return this.localDeliveryRecord[key].some(({ name, quantity, unitPrice }) => {
						return (name && name.trim().length > 0) || (quantity && quantity.trim().length > 0) || (unitPrice && unitPrice.trim().length > 0);
					})
				}
				if (itemsDifferent(this.localDeliveryRecord[key], this.deliveryRecord[key])) return true;
			})) return true;
			if (dtKeys.some(key => {
				if (dateTimeDifferent(this.deliveryRecord[key], this.localDeliveryRecord[key])) return true;
			})) return true;
			const keys = ["supplier", "purchaseOrder", "receivedBy", "acceptedBy", "rejectedBy"];
			return keys.some(key => this.localDeliveryRecord[key] !== this.deliveryRecord[key]);
		},
		
		suppliers() {
			return this.$store.state.contents.suppliers.map(({ _id, name, supplierNo }) => {
				return {
					_id: _id,
					name: name,
					supplierNo: supplierNo
				}
			});
		},
		
		purchaseOrders() {
			return this.$store.state.contents.purchaseOrders.map(({ _id, poNo, items, totalAmount, issuedAt }) => {
				return {
					_id : _id,
					poNo: poNo,
					items: items.length,
					totalAmount: totalAmount,
					issuedAt: issuedAt ? format(new Date(issuedAt), "yyyy-MM-dd") : "N/A",
				}
			})
		}
	},
	
	methods: {
		unloadHandler(event) {
			if (this.savable) {
				event.preventDefault();
				event.returnValue = '';
			}
		},
		
		resetModal(bvModalEvent) {
			if (bvModalEvent.trigger !== "ok") {
				this.password = "";
				this.pwInvalid = false;
				this.recordNo = "";
				this.recordNoInvalid = false;
			}
		},
		
		initDeliveryRecord() {
			if (this.deliveryRecord) {
				const obj = {...this.deliveryRecord};
				obj.itemsReceived = [];
				obj.itemsAccepted = [];
				obj.itemsRejected = [];
				this.itemsReceivedInvalid = [];
				this.itemsAcceptedInvalid = [];
				this.itemsRejectedInvalid = [];
				this.deliveryRecord.itemsReceived.forEach(item => {
					obj.itemsReceived.push({...item});
					this.itemsReceivedInvalid.push({ name: "", quantity: "", unitPrice: "" });
				});
				if (this.deliveryRecord.itemsAccepted.length > 0) {
					this.deliveryRecord.itemsAccepted.forEach(item => {
						obj.itemsAccepted.push({...item});
						this.itemsAcceptedInvalid.push({ name: "", quantity: "", unitPrice: "" });
					});
				} else {
					obj.itemsAccepted.push({ name: "", quantity: "", unitPrice: "" });
					this.itemsAcceptedInvalid.push({ name: "", quantity: "", unitPrice: "" });
				}
				if (this.deliveryRecord.itemsRejected.length > 0) {
					this.deliveryRecord.itemsRejected.forEach(item => {
						obj.itemsRejected.push({...item});
						this.itemsRejectedInvalid.push({ name: "", quantity: "", unitPrice: "" });
					});
				} else {
					obj.itemsRejected.push({ name: "", quantity: "", unitPrice: "" });
					this.itemsRejectedInvalid.push({ name: "", quantity: "", unitPrice: "" });
				}
				const supplier = this.$store.state.contents.suppliers.find(({ _id }) => _id === obj.supplier);
				const po = this.$store.state.contents.purchaseOrders.find(({ _id }) => _id === obj.purchaseOrder);
				const receivedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.receivedBy);
				const acceptedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.acceptedBy);
				const rejectedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.rejectedBy);
				const createdBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.createdBy);
				const updatedBy = this.$store.state.contents.staffs.find(({ _id }) => _id === obj.updatedBy);
				// receivedAt, createdAt and updatedAt must present in the database, need no nullish check
				const receivedAtDT = new Date(obj.receivedAt);
				const createdAtDT = new Date(obj.createdAt);
				const updatedAtDT = new Date(obj.updatedAt);
				// added properties
				obj.supplierName = supplier ? `${supplier.name} (${supplier.supplierNo})` : "";
				obj.poNo = po ? po.poNo : "";
				obj.receivedByName = receivedBy ? `${receivedBy.lastName} ${receivedBy.firstName}, ${receivedBy.preferredName} (${receivedBy.staffNo})` : "";
				obj.acceptedByName = acceptedBy ? `${acceptedBy.lastName} ${acceptedBy.firstName}, ${acceptedBy.preferredName} (${acceptedBy.staffNo})` : "";
				obj.rejectedByName = rejectedBy ? `${rejectedBy.lastName} ${rejectedBy.firstName}, ${rejectedBy.preferredName} (${rejectedBy.staffNo})` : "";
				obj.createdByName = createdBy ? `${createdBy.lastName} ${createdBy.firstName}, ${createdBy.preferredName} (${createdBy.staffNo})` : "N/A";
				obj.updatedByName = updatedBy ? `${updatedBy.lastName} ${updatedBy.firstName}, ${updatedBy.preferredName} (${updatedBy.staffNo})` : "N/A";
				obj.receivedAtDT = { date: format(receivedAtDT, "yyyy-MM-dd"), time: format(receivedAtDT, "HH:mm:ss")};
				obj.acceptedAtDT = obj.acceptedAt ? { date: format(new Date(obj.acceptedAt), "yyyy-MM-dd"), time: format(new Date(obj.acceptedAt), "HH:mm:ss")} : { date: "", time: ""};
				obj.rejectedAtDT = obj.rejectedAt ? { date: format(new Date(obj.rejectedAt), "yyyy-MM-dd"), time: format(new Date(obj.rejectedAt), "HH:mm:ss")} : { date: "", time: ""};
				obj.createdAtDT = { date: format(createdAtDT, "yyyy-MM-dd"), time: format(createdAtDT, "HH:mm:ss")};
				obj.updatedAtDT = { date: format(updatedAtDT, "yyyy-MM-dd"), time: format(updatedAtDT, "HH:mm:ss")};
				this.localDeliveryRecord = obj;
			} else {
				this.localDeliveryRecord._id = uuidv4();
				this.localDeliveryRecord.recordNo = "REC-" + this.localDeliveryRecord._id.split("-")[0].toUpperCase();
			}
		},
		
		addDeleteItems(key, option, index) {
			if (option === "add") {
				this.localDeliveryRecord[key].splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
				this[`${key}Invalid`].splice(index + 1, 0, { name: "", quantity: "", unitPrice: "" });
			} else {
				this.localDeliveryRecord[key].splice(index, 1);
				this[`${key}Invalid`].splice(index, 1);
			}
		},
		
		selectSupplier(supplier) {
			this.localDeliveryRecord.supplier = supplier._id;
			this.localDeliveryRecord.supplierName = `${supplier.name} (${supplier.supplierNo})`;
			this.invalidInputs.supplier = "";
		},
		
		selectPurchaseOrder(po) {
			this.localDeliveryRecord.purchaseOrder = po._id;
			this.localDeliveryRecord.poNo = po.poNo;
			this.invalidInputs.purchaseOrder = "";
		},
		
		updateReceivedTotal() {
			this.localDeliveryRecord.receivedTotal = this.localDeliveryRecord.itemsReceived.reduce((acc, { quantity, unitPrice }) => {
				const parsedPrice = parseFloat(unitPrice);
				const parsedQty = parseInt(quantity);
				if (parsedPrice && parsedQty) return acc + parsedPrice * parsedQty;
				return acc;
			}, 0).toFixed();
		},
		
		updateDT(key, keyDT, dateTime, value) {
			this.localDeliveryRecord[keyDT][dateTime] = value;
			this.invalidInputs[keyDT][dateTime] = "";
			if (dateTime === "date") {
				this.localDeliveryRecord[key] = this.localDeliveryRecord[keyDT].time ?
					new Date(`${value}T${this.localDeliveryRecord[keyDT].time}`).toISOString() : value;
			} else {
				this.localDeliveryRecord[key] = this.localDeliveryRecord[keyDT].date ?
					new Date(`${this.localDeliveryRecord[keyDT].date}T${value}`).toISOString() : value;
			}
		},
		
		updateItems(itemKey, key, value, index) {
			this.localDeliveryRecord[itemKey][index][key] = value;
			switch (key) {
				case "quantity":
					this[`${itemKey}Invalid`][index][key] = isNumeric(value) && parseFloat(value) >= 0 ? "" : "invalid";
					this.updateReceivedTotal();
					break;
				case "unitPrice":
					// bind to change event instead of input event
					if (isNumeric(value) && parseFloat(value) >= 0) {
						this.localDeliveryRecord[itemKey][index][key] = parseFloat(value).toFixed(2);
						this[`${itemKey}Invalid`][index][key] = "";
					} else {
						this[`${itemKey}Invalid`][index][key] = "invalid";
					}
					this.updateReceivedTotal();
					break;
				default:
					this[`${itemKey}Invalid`][index][key] = value.trim().length > 0 ? "" : "invalid";
					break;
			}
			if (itemKey !== "itemsReceived" && (!value || value.trim().length === 0)) this[`${itemKey}Invalid`][index][key] = "";
			
		},
		
		// only set invalid, shouldn't set it back to empty string
		// nullish check only, no other validations since they are being taken care of in input event handlers
		dataInvalid() {
			const recordKeys = ["supplier", "purchaseOrder", "receivedBy"];
			const itemKeys = ["name", "quantity", "unitPrice"];
			recordKeys.forEach(key => {
				if (!this.localDeliveryRecord[key] ||
					this.localDeliveryRecord[key].trim().length === 0) this.invalidInputs[key] = "invalid";
			});
			this.itemsReceivedInvalid.forEach((item, index) => itemKeys.forEach(key => {
				if (!this.localDeliveryRecord.itemsReceived[index][key] ||
					this.localDeliveryRecord.itemsReceived[index][key].trim().length === 0) item[key] = "invalid"; //object reference
			}));
			if (this.localDeliveryRecord.receivedAt) {
				if (!this.localDeliveryRecord.receivedAtDT.date) this.invalidInputs.receivedAtDT.date = "invalid";
				if (!this.localDeliveryRecord.receivedAtDT.time) this.invalidInputs.receivedAtDT.time = "invalid";
			} else {
				this.invalidInputs.receivedAtDT.date = "invalid";
				this.invalidInputs.receivedAtDT.time = "invalid";
			}
			if (this.localDeliveryRecord.acceptedAt) {
				if (!this.localDeliveryRecord.acceptedAtDT.date) this.invalidInputs.acceptedAtDT.date = "invalid";
				if (!this.localDeliveryRecord.acceptedAtDT.time) this.invalidInputs.acceptedAtDT.time = "invalid";
			}
			if (this.localDeliveryRecord.rejectedAt) {
				if (!this.localDeliveryRecord.rejectedAtDT.date) this.invalidInputs.rejectedAtDT.date = "invalid";
				if (!this.localDeliveryRecord.rejectedAtDT.time) this.invalidInputs.rejectedAtDT.time = "invalid";
			}
			return Object.keys(this.invalidInputs).some(key => {
				if (key.includes("DT")) {
					return this.invalidInputs[key].date === "invalid" ||
						this.invalidInputs[key].time === "invalid";
				} else {
					return this.invalidInputs[key] === "invalid";
				}
			}) || this.itemsReceivedInvalid.some(item => itemKeys.some(key => item[key] === "invalid")) ||
				this.itemsAcceptedInvalid.some(item => itemKeys.some(key => item[key] === "invalid")) ||
				this.itemsRejectedInvalid.some(item => itemKeys.some(key => item[key] === "invalid"));
		},
		
		async saveDeliveryRecord() {
			this.backBtnTitle = "BACK";
			this.backBtnClass = "btn-custom-light mr-2";
			if (this.dataInvalid()) {
				this.msg = "Please Fix Invalid Fields!";
				this.msgInvalid = true;
			} else {
				const deleteKeys = ["supplierName", "poNo", "createdByName", "updatedByName", "receivedByName",
					"AcceptedByName", "rejectedByName", "acceptedAtDT", "rejectedAtDT",
					"receivedAtDT", "createdAtDT", "updatedAtDT"];
				const itemsAccepted = [];
				const itemsRejected = [];
				const now = new Date().toISOString();
				const deliveryRecord = {...this.localDeliveryRecord};
				this.localDeliveryRecord.itemsAccepted.forEach(item => {
					if ((item.name && item.name.trim().length > 0) ||
						(item.quantity && item.quantity.trim().length > 0) ||
						(item.unitPrice && item.unitPrice.trim().length > 0)) itemsAccepted.push(item);
				});
				this.localDeliveryRecord.itemsRejected.forEach(item => {
					if ((item.name && item.name.trim().length > 0) ||
						(item.quantity && item.quantity.trim().length > 0) ||
						(item.unitPrice && item.unitPrice.trim().length > 0)) itemsRejected.push(item);
				});
				deliveryRecord.itemsAccepted = itemsAccepted;
				deliveryRecord.itemsRejected = itemsRejected;
				if (!deliveryRecord.createdAt) {
					deliveryRecord.createdAt = now;
					deliveryRecord.createdBy = this.$store.state.user.user._id;
				}
				deliveryRecord.updatedAt = now;
				deliveryRecord.updatedBy = this.$store.state.user.user._id;
				deleteKeys.forEach(key => delete deliveryRecord[key]);
				await this.$store.dispatch("contents/updateContent", { key: "deliveryRecords", value: deliveryRecord});
				if (this.recordId) {
					this.initDeliveryRecord();
					this.msg = "Previous Changes Saved!";
					this.msgInvalid = false;
				} else {
					// bypassing beforeRouteLeave guard
					this.msg = "Leave Without Saving?";
					await this.$router.push(`/supplier/record/${deliveryRecord._id}`);
					// need to run initDeliveryRecord after route change since deliveryRecordId prop was undefined
					this.initDeliveryRecord();
					// vue router reuses this component
					this.msgInvalid = false;
					this.msg = "New Delivery Record Created!";
				}
			}
		},
		
		async deleteDeliveryRecord(bvModalEvent) {
			if (this.password !== this.$store.state.user.user.password) {
				bvModalEvent.preventDefault();
				this.pwInvalid = true;
				if (this.recordNo !== this.deliveryRecord.recordNo) this.recordNoInvalid = true;
			} else if (this.recordNo !== this.deliveryRecord.recordNo) {
				bvModalEvent.preventDefault();
				this.recordNoInvalid = true;
			} else {
				await this.$store.dispatch("contents/deleteContent", { key: "deliveryRecords", _id: this.deliveryRecord._id});
				// bypassing beforeRouteLeave guard
				this.msg = "Leave Without Saving?";
				await this.$router.push("/supplier/record");
			}
		},
		
		async goBack() {
			if (this.msg === "Leave Without Saving?") {
				await this.$router.push(this.toRoute);
			} else {
				await this.$router.push("/supplier/record");
			}
		}
	}
}
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/form";
</style>